import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { SliceZone } from '@prismicio/react'
import { Layout } from '../../components/Layout'
import { components } from '../../slices'
import 'bootstrap/dist/css/bootstrap.min.css';

function Post(props) {
  const { prismicPost } = props.data
  const post = prismicPost.data
  const postInfoSlice = post.body.find(item => item.slice_type === 'post_info');
  const seo = {
    title: prismicPost.data.seo_title || prismicPost.data.title.text,
    description: prismicPost.data.seo_description || postInfoSlice.primary.description.text.substring(0, 297) + "...",
    keywords: prismicPost.data.seo_keywords,
  }


  const {title, body} = post
  const cntx = {title}

  return (
    <Layout seo={seo}>
      <SliceZone slices={body} components={components} context={cntx}/>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query($id: String!) {
    prismicPost(id: { eq: $id }) {
      data {
        seo_title
        seo_description
        seo_keywords
        title {
          text
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ... PostDataBodyPostInfo
        }
      }
    }
  }
`